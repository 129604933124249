import { render, staticRenderFns } from "./DetailPost.vue?vue&type=template&id=bc344988&scoped=true"
import script from "./DetailPost.vue?vue&type=script&lang=js"
export * from "./DetailPost.vue?vue&type=script&lang=js"
import style0 from "./DetailPost.vue?vue&type=style&index=0&id=bc344988&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc344988",
  null
  
)

export default component.exports