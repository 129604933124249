<template>
  <div class="columns is-multiline">
    <div class="column is-12 title-blog">
      <p class="title-blog">Información General</p>
    </div>
    <div class="column is-6">
      <div class="columns is-multiline is-mobile">
        <div class="column is-12"><strong> Título General</strong></div>
        <div class="column is-12"> {{postData.name}} </div>
        <div class="column is-12"><strong> Autor</strong></div>
        <div class="column is-12"> {{postData.author}} </div>
        <div class="column is-12"><strong> Categorías</strong></div>
        <div class="column is-12"> {{listCategories()}} </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-multiline is-mobile">
        <div class="column is-12">
          <p class="subtitle"><strong>Portada</strong></p>
          <figure class="image is-5by3">
            <img :src="`${ASSETS_URL}${postData.image}`">
          </figure>
        </div>
      </div>
    </div>
    <div class="column is-12"><strong> Descripción corta</strong></div>
    <div class="column is-12" v-html="postData.short_description"> </div>
    <div class="column is-12"><strong> Descripción larga</strong></div>
    <div class="column is-12" v-html="postData.long_description"> </div>
  </div>
</template>

<script>
export default {
  props: {
    postData: {
      type: Object
    }
  },
  data () {
    return {
      ASSETS_URL: process.env.VUE_APP_ASSETS_URL,
      categories: ''
    }
  },
  methods: {
    listCategories () {
      return this.postData.categories.reduce((accum, element, index) => {
        accum += `${element.name}${(index < this.postData.categories.length - 1) ? ', ' : ''}`
        return accum
      }, '')
    }
  }
}
</script>

<style scoped>
  .title-blog {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.2px;
    text-align: left;
    color: #292A30;
    margin-bottom: 10px;
  }
  .image img {
    object-fit: cover;
  }
</style>
