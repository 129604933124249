var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns is-multiline"},[_vm._m(0),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_vm._m(1),_c('div',{staticClass:"column is-12"},[_vm._v(" "+_vm._s(_vm.postData.name)+" ")]),_vm._m(2),_c('div',{staticClass:"column is-12"},[_vm._v(" "+_vm._s(_vm.postData.author)+" ")]),_vm._m(3),_c('div',{staticClass:"column is-12"},[_vm._v(" "+_vm._s(_vm.listCategories())+" ")])])]),_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-12"},[_vm._m(4),_c('figure',{staticClass:"image is-5by3"},[_c('img',{attrs:{"src":`${_vm.ASSETS_URL}${_vm.postData.image}`}})])])])]),_vm._m(5),_c('div',{staticClass:"column is-12",domProps:{"innerHTML":_vm._s(_vm.postData.short_description)}}),_vm._m(6),_c('div',{staticClass:"column is-12",domProps:{"innerHTML":_vm._s(_vm.postData.long_description)}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-12 title-blog"},[_c('p',{staticClass:"title-blog"},[_vm._v("Información General")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-12"},[_c('strong',[_vm._v(" Título General")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-12"},[_c('strong',[_vm._v(" Autor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-12"},[_c('strong',[_vm._v(" Categorías")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle"},[_c('strong',[_vm._v("Portada")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-12"},[_c('strong',[_vm._v(" Descripción corta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-12"},[_c('strong',[_vm._v(" Descripción larga")])])
}]

export { render, staticRenderFns }